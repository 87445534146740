<template>
    <!-- <div id="toolbar">
        <div class="left" v-if="dashboardEditMode">
            <div class="action">
                <div class="icon">
                    <i class="fa-thin fa-file-signature"></i>
                </div>
                <div class="name">Name bearbeiten</div>
            </div>
            <div class="action">
                <div class="icon">
                    <i class="fa-thin fa-layer-plus"></i>
                </div>
                <div class="name">Ansicht hinzufügen</div>
            </div>
            <div class="action">
                <div class="icon">
                    <i class="fa-thin fa-delete-left"></i>
                </div>
                <div class="name">Dashboard löschen</div>
            </div>
            <div class="action" @click="dashboardEditMode = !dashboardEditMode">
                <div class="icon">
                    <i class="fa-thin fa-angle-left"></i>
                </div>
                <div class="name">Zurück</div>
            </div>
        </div>
        <div class="left" v-if="!dashboardEditMode">
            <div class="action" @click="dashboardEditMode = !dashboardEditMode">
                <div class="icon">
                    <i class="fa-thin fa-pen"></i>
                </div>
                <div class="name">Dashboard bearbeiten</div>
            </div>
        </div>

        <div class="right">
            <div class="action">
                <div class="icon">
                    <i class="fa-thin fa-right-from-bracket"></i>
                </div>
                <div class="name">Ausloggen</div>
            </div>
        </div>
    </div>
    -->

    <div id="content">
        <div class="container">
            <h3>Dashboard</h3>
            <h4>Wetterstation Klimawald Freienohl</h4>

            <!--
            <div class="row mt-3 justify-content-md-center">
                <div class="col-sm-12">
                    <div class="card">
                        <ejs-maps background="#191919" :zoomSettings='zoomSettings' :centerPosition='centerPosition' height="400">
                            <e-layers>
                                <e-layer :urlTemplate='urlTemplate' :markerSettings='markerSettings'></e-layer>
                            </e-layers>
                        </ejs-maps>
                    </div>
                </div>
            </div>
            -->

            <div class="row mt-3"><!-- justify-content-md-center -->
                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                    <div class="card">
                        <div class="sensor-value">
                            <div class="data">
                                <div class="icon">
                                    <i class="fa-thin fa-wind"></i>
                                </div>
                                <div class="details">
                                    <div class="title">Windrichtung</div>
                                    <div class="description">Küppelturm</div>
                                </div>
                                <div class="value" v-html="windDegreeString"></div>
                            </div>
                        </div>

                        <div class="compass-container">
                            <ejs-circulargauge background="transparent" ref="circulargauge" :load='load' align='center'
                                               id='direction-container' :axisLabelRender='axisLabelRender' height="250" width="250" style="transform: scale(0.75)">
                                <e-axes>
                                    <e-axis :radius='radius' :startAngle='startAngle' minimum=0 maximum=8 :endAngle='endAngle'
                                            :majorTicks='majorTicks' :lineStyle='lineStyle' :minorTicks='minorTicks'
                                            :labelStyle='labelStyle'>
                                        <e-pointers>
                                            <e-pointer :value='windDegreeValue' :radius='pointerRadius1' :color='color1'
                                                       :pointerWidth='pointerWidth1' :cap='cap1' :animation='animation1'></e-pointer>
                                        </e-pointers>
                                    </e-axis>
                                </e-axes>
                            </ejs-circulargauge>
                        </div>
                    </div>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                    <div class="card">
                        <div class="sensor-value">
                            <div class="data">
                                <div class="icon">
                                    <i class="fa-thin fa-temperature-half"></i>
                                </div>
                                <div class="details">
                                    <div class="title">Temperatur</div>
                                    <div class="description">Lufttemperatur (1,5m)</div>
                                </div>
                                <div class="value">{{ tempValue }} °C</div>
                            </div>
                        </div>
                        <div id="chart_sparkline"></div>
                    </div>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                    <div class="card">
                        <div class="sensor-value">
                            <div class="data">
                                <div class="icon">
                                    <i class="fa-thin fa-temperature-half"></i>
                                </div>
                                <div class="details">
                                    <div class="title">Temperatur</div>
                                    <div class="description">Kalibrierlabor</div>
                                </div>
                                <div class="value">18.5 °C</div>
                            </div>
                        </div>
                    </div>
                    <div class="card">
                        <div class="sensor-value">
                            <div class="data">
                                <div class="icon">
                                    <i class="fa-thin fa-droplet-percent"></i>
                                </div>
                                <div class="details">
                                    <div class="title">Feuchtigkeit</div>
                                    <div class="description">Kalibrierlabor</div>
                                </div>
                                <div class="value">{{ humidity }} %</div>
                            </div>
                        </div>
                        <div class="line_percentage_value">
                            <div class="line" style="background-color:#034B97" :style="'width:' + humidity + '%'"></div>
                        </div>
                    </div>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-3">
                    <div class="card chart">
                        <div class="sensor-value">
                            <div class="data">
                                <div class="icon">
                                    <i class="fa-thin fa-lightbulb"></i>
                                </div>
                                <div class="details">
                                    <div class="title">Licht</div>
                                    <div class="description">Kalibrierlabor</div>
                                </div>
                                <div class="value">1250 Lux</div>
                            </div>
                        </div>
                        <div id="light_chart" class="test"></div>
                    </div>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-6">
                    <div class="card">
                        <div class="sensor-value">
                            <div class="data">
                                <div class="icon">
                                    <i class="fa-thin fa-droplet-percent"></i>
                                </div>
                                <div class="details">
                                    <div class="title">Feuchtigkeit ⌀</div>
                                    <div class="description">Feld 61 (112 ha)</div>
                                </div>
                                <div class="value">
                                    <i class="fa-thin fa-triangle-exclamation" style="color: darkorange;font-size: 32px"></i>
                                </div>
                            </div>
                        </div>
                        <div id="chart_heatmap"></div>
                    </div>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-6">
                    <div class="card">
                        <div class="sensor-value">
                            <div class="data">
                                <div class="icon">
                                    <i class="fa-thin fa-solar-panel"></i>
                                </div>
                                <div class="details">
                                    <div class="title">Solar</div>
                                    <div class="description">Hauptdach</div>
                                </div>
                                <div class="value">~ 385 W/m²</div>
                            </div>
                        </div>
                        <div id="chart_sparkbar"></div>
                    </div>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-6">
                    <div class="card">
                        <div class="sensor-value">
                            <div class="data">
                                <div class="icon">
                                    <i class="fa-thin fa-solar-panel"></i>
                                </div>
                                <div class="details">
                                    <div class="title">Solar</div>
                                    <div class="description">Hauptdach</div>
                                </div>
                                <div class="value">715 W/m²</div>
                            </div>
                        </div>
                        <div id="chart_points"></div>
                    </div>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-4 col-xl-6">
                    <div class="card">
                        <div class="sensor-value">
                            <div class="data">
                                <div class="icon">
                                    <i class="fa-thin fa-temperature-half"></i>
                                </div>
                                <div class="details">
                                    <div class="title">Temperatur</div>
                                    <div class="description">Lufttemperatur (1,5m)</div>
                                </div>
                                <div class="value">{{ tempValue }} °C</div>
                            </div>
                        </div>
                        <div id="table" class="tableFixHead">
                            <table>
                                <thead>
                                    <tr><th>Datum</th><th>Messwert</th></tr>
                                </thead>
                                <tbody>
                                <tr v-for="data in tempValues" v-bind:key="data.date"><td>{{ $filters.formatDate(data[0] - 7200000) }} Uhr</td><td>{{ data[1] }} °C</td></tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-3 justify-content-md-center">
                <div class="addElement" @click="showModalAddComponent = true">
                    <i class="fa-thin fa-circle-plus"></i> Hinzufügen
                </div>
            </div>
        </div>
    </div>


    <div id="modalBackground" v-if="showModalAddComponent" @click="showModalAddComponentPage = 'types';showModalAddComponent = false;selectedDesign = null;selectedSensor = [];selectedTypes = [];"></div>
    <div id="modalAddComponent" class="pceModal" v-if="showModalAddComponent">
        <div class="head">
            <div class="title">Ansicht hinzufügen</div>
            <div class="close" @click="showModalAddComponentPage = 'types';showModalAddComponent = false;selectedDesign = null;selectedSensor = [];selectedTypes = [];">
                <i class="fa-thin fa-xmark"></i>
            </div>
        </div>

        <div class="content" v-if="showModalAddComponentPage === 'types'">
            <small style="text-decoration: underline;">Messgröße(n) auswählen</small>
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-4 field" v-for="typ in GetTypes()" v-bind:key="typ" @click="TriggerType(typ)" :class="selectedTypes.indexOf(typ) >= 0 ? 'active' : ''">
                    <div class="name">
                        <span v-html="typ.icon" style="font-size: 24px; margin-right: 10px"></span>
                        {{ typ.name }}
                    </div>
                </div>
            </div>
        </div>
        <div class="footer" v-if="showModalAddComponentPage === 'types'">
            <div class="next" @click="showModalAddComponentPage = 'sensors'" v-if="selectedTypes.length > 0">Weiter <i class="fa-thin fa-angle-right"></i></div>
        </div>

        <div class="content" v-if="showModalAddComponentPage === 'sensors'">
            <small style="text-decoration: underline;">Sensor(en) auswählen</small>
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-4 field" :class="selectedSensor.indexOf(sensor.sensor) >= 0 ? 'active' : ''" @click="TriggerSensor(sensor.sensor)" v-for="sensor in GetSensorsByTypes()" v-bind:key="sensor">
                    <div class="name">{{ sensor.sensor.desc }}</div>
                    <small><b>{{ sensor.station.name }}</b></small>
                </div>
            </div>
        </div>
        <div class="footer" v-if="showModalAddComponentPage === 'sensors'">
            <div class="back" @click="showModalAddComponentPage = 'types';"><i class="fa-thin fa-angle-left"></i> Zurück</div>
            <div class="next" @click="showModalAddComponentPage = 'display'" v-if="selectedSensor.length > 0">Weiter <i class="fa-thin fa-angle-right"></i></div>
        </div>



        <div class="content" v-if="showModalAddComponentPage === 'display'">
            <small style="text-decoration: underline;">Darstellung auswählen</small>
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-4 field" :class="selectedDesign === design ? 'active' : ''" @click="selectedDesign = design" v-for="design in GetDesignsBySensors()" v-bind:key="design.name">
                    <div v-if="design.img !== undefined" class="img" :style="{ backgroundImage: 'url(img/types/' + design.img + ')' }"></div>
                    <div class="name">{{ design.name }}</div>
                </div>
            </div>
        </div>
        <div class="footer" v-if="showModalAddComponentPage === 'display'">
            <div class="back" @click="showModalAddComponentPage = 'sensors';"><i class="fa-thin fa-angle-left"></i> Zurück</div>
            <div class="next" @click="showModalAddComponentPage = 'settings'" v-if="selectedDesign != null && selectedDesign.config !== undefined">Weiter <i class="fa-thin fa-angle-right"></i></div>
            <div class="next" v-if="selectedDesign != null && selectedDesign.config === undefined">Hinzufügen <i class="fa-thin fa-angle-right"></i></div>
        </div>




        <div class="content" v-if="showModalAddComponentPage === 'settings'">
            <small style="text-decoration: underline;">Optionen auswählen & anpassen</small>
            <div class="row">
                <fieldset v-for="sensor in selectedSensor" v-bind:key="sensor">
                    <legend><b>{{ GetStationFromSensor(sensor).name }}</b> - {{ sensor.desc }}</legend>
                    <div class="col-sm-12" v-for="option in selectedDesign.config" v-bind:key="option" style="padding:10px">
                        <div v-if="option.typ === 'colors'">
                            <div class="form-group">
                                <label style="margin-right: 20px">Farbe</label>
                                <input type="color" class="form-control" value="#ffffff">
                            </div>
                        </div>

                        <div v-if="option.typ === 'optional_bar' && option.types.indexOf(sensor.typ) >= 0">
                            <div class="form-group">
                                <input class="form-check-input" type="checkbox" value="" id="checkOptionalBar" v-model="checkBoxChecked">
                                <label class="form-check-label" for="checkOptionalBar" style="margin-left: 15px">
                                    Grafische Bar
                                </label>
                            </div>
                            <div class="form-group" v-if="checkBoxChecked">
                                <label style="margin-right: 20px">Farbe</label>
                                <input type="color" class="form-control" value="#ffffff">
                            </div>
                        </div>
                    </div>
                </fieldset>
            </div>
        </div>
        <div class="footer" v-if="showModalAddComponentPage === 'settings'">
            <div class="back" @click="showModalAddComponentPage = 'display';"><i class="fa-thin fa-angle-left"></i> Zurück</div>
            <div class="next">Hinzufügen <i class="fa-thin fa-angle-right"></i></div>
        </div>
    </div>
</template>

<script>
import ApexCharts from 'apexcharts'

import {Zoom, Marker, MapsTooltip} from '@syncfusion/ej2-vue-maps';
import {world_map} from 'world-map'
// import {DashboardLayoutComponent, PanelDirective, PanelsDirective} from "@syncfusion/ej2-vue-layouts";


export default {
    name: "DashboardView",
    /*
      components: {
      "ejs-dashboardlayout": DashboardLayoutComponent,
      "e-panels": PanelsDirective,
      "e-panel": PanelDirective,
    },
     */

    data() {
        return {
            checkBoxChecked: false,

            selectedTypes: [],
            selectedSensor: [],
            selectedDesign: null,
            showModalAddComponent: false,
            showModalAddComponentPage: "types",

            types: [
                {
                    id: 1,
                    typ: "temperature",
                    name: "Temperatur",
                    icon: '<i class="fa-thin fa-temperature-half"></i>'
                },
                {
                    id: 2,
                    typ: "humidity",
                    name: "Feuchtigkeit",
                    icon: '<i class="fa-thin fa-droplet-percent"></i>'
                },
                {
                    id: 3,
                    typ: "wind_direction",
                    name: "Windrichtung",
                    icon: '<i class="fa-thin fa-compass"></i>'
                },
                {
                    id: 4,
                    typ: "wind_speed",
                    name: "Windgeschwindigkeit",
                    icon: '<i class="fa-thin fa-wind"></i>'
                },
                {
                    id: 5,
                    typ: "solar",
                    name: "Solar",
                    icon: '<i class="fa-thin fa-solar-panel"></i>'
                },
                {
                    id: 6,
                    typ: "light",
                    name: "Licht",
                    icon: '<i class="fa-thin fa-lightbulb"></i>'
                }
            ],

            designs: [
                {
                    name: "Wertdarstellung",
                    img: "value.png",
                    types: ["temperature", "wind_direction", "wind_speed"],
                    max_sensors: 1,
                    config: [
                        {typ: "optional_bar", types: ["temperature", "wind_speed"],},
                        // {typ: "alarms"}
                    ]
                },
                {
                    name: "Liniendiagramm",
                    img: "line.png",
                    types: ["temperature", "wind_speed"],
                    max_sensors: 5,
                    config: [
                        {typ: "colors"}
                    ]
                },
                {
                    name: "Balkendiagramm",
                    img: "balken.png",
                    max_sensors: 5,
                    types: ["temperature", "wind_speed"],
                    config: [
                        {typ: "colors"}
                    ]
                },
                {
                    name: "Punktdiagramm",
                    img: "points.png",
                    max_sensors: 5,
                    types: ["temperature", "wind_direction", "wind_speed"],
                    config: [
                        {typ: "colors"}
                    ]
                },
                {
                    name: "Tacho",
                    img: "tacho.png",
                    max_sensors: 1,
                    types: ["temperature", "wind_speed"]
                },
                {
                    name: "Tabelle",
                    max_sensors: 5,
                    types: ["temperature", "wind_direction", "wind_speed"]
                },
                {
                    name: "Kompass",
                    img: "compass.png",
                    max_sensors: 1,
                    types: ["wind_direction"]
                },
                {
                    name: "Heatmap",
                    img: "heatmap.png",
                    max_sensors: 1,
                    types: ["temperature", "wind_speed"]
                }
            ],

            stations: [
                {
                    id: 1,
                    name: "Klimawald Freienohl",
                    sensors: [
                        {
                            id: 1,
                            name: "temp_floor_10cm",
                            desc: "Bodensensor 10cm Tiefe",
                            typ: "temperature"
                        },
                        {
                            id: 2,
                            name: "temp_floor_20cm",
                            desc: "Bodensensor 20cm Tiefe",
                            typ: "temperature"
                        },
                        {
                            id: 3,
                            name: "wind_direction",
                            desc: "Windrichtung 1,5m Höhe",
                            typ: "wind_direction"
                        },
                        {
                            id: 4,
                            name: "wind_speed",
                            desc: "Windgeschwindigkeit 1,5m Höhe",
                            typ: "wind_speed"
                        }
                    ]
                },
                {
                    id: 2,
                    name: "Meschede-Olpe",
                    sensors: [
                        {
                            id: 1,
                            name: "temp_floor_10cm",
                            desc: "Bodensensor 10cm Tiefe",
                            typ: "temperature"
                        },
                        {
                            id: 2,
                            name: "temp_floor_20cm",
                            desc: "Bodensensor 20cm Tiefe",
                            typ: "temperature"
                        },
                        {
                            id: 3,
                            name: "wind_direction",
                            desc: "Windrichtung 1,5m Höhe",
                            typ: "wind_direction"
                        },
                        {
                            id: 4,
                            name: "wind_speed",
                            desc: "Windgeschwindigkeit 1,5m Höhe",
                            typ: "wind_speed"
                        }
                    ]
                }
            ],

            humidity: this.genRand(5, 100, 2),
            windDegree: 0,
            windDegreeString: "",
            windDegreeValue: 1,
            tempValue: "",
            tempValues: [],
            radius: '75%',
            lineStyle: {
                width: 10
            },
            aspectRatio: 100 / 85,
            cellSpacing: [10, 10],
            resizableHandles: ['e-south-east', 'e-east', 'e-west', 'e-north', 'e-south'],
            urlTemplate: "https://tile.openstreetmap.org/level/tileX/tileY.png",
            dataSource: [
                {
                    latitude: 51.38463148505049,
                    longitude: 8.163451727074289,
                    name: "PCE-Deutschland GmbH"
                }
            ],

            markerSettings: [{
                dataSource: [
                    {
                        latitude: 51.38463148505049,
                        longitude: 8.163451727074289,
                        name: "PCE-Deutschland GmbH",
                    }
                ],
                visible:true,
                height: 30,
                width: 30,
                animationDuration: 0,
                tooltipSettings: {
                    visible: true,
                    valuePath: 'name'
                }
            }],

            zoomSettings: {
                enable: true,
                zoomFactor: 15,
                minZoom: 4,
                maxZoom: 19,
                enablePanning: true,
                mouseWheelZoom : true,
                pinchZooming: false,
                enableSelectionZooming: false
            },
            centerPosition: {
                latitude: 51.38463148505049,
                longitude: 8.163451727074289
            },

            shapeData: world_map,

            shapeSettings: {
                fill: "#9CBF4E",
                strokeThickness: "0.5",
                stroke: "White",
                highlightStroke: "White",
                highlightColor: "#BC5353",
                highlightBorderWidth: "1"
            },

            mapsArea: {
                background: "blue"
            },

            labelStyle: {
                font: {
                    size: '12px',
                    fontFamily: 'Segoe UI',
                    color: "white"
                },
                autoAngle: true,
                hiddenLabel: 'Last'
            },
            majorTicks: {
                height: 15,
                interval: 1
            },
            minorTicks: {
                height: 10,
                interval: 0.5
            },
            startAngle: 0,
            endAngle: 360,
            value1: 7,
            pointerRadius1: '50%',
            color1: '#f03e3e',
            pointerWidth1: 20,
            cap1: {
                radius: 0
            },
            animation1: {
                enable: false
            },
        }
    },

    provide: {
        maps: [Zoom, Marker, MapsTooltip]
    },

    created() {
        this.updateCompass();
    },

    mounted() {
        this.humidity_line_chart();
        this.heatmap();

        setInterval(() => {
            this.humidity = this.genRand(5, 100, 2)
            this.updateCompass();
        }, 3000);
    }
    ,

    methods: {
        GetDesignsBySensors()
        {
            let designs = [];
            let typs = [];

            for(let i = 0; i < this.selectedSensor.length; i++)
            {
                if(typs.indexOf(this.selectedSensor[i].typ) === -1)
                    typs.push(this.selectedSensor[i].typ)
            }

            for(let i = 0; i < this.designs.length; i++)
            {
                let bCanSelected = true;

                if(this.designs[i].max_sensors < this.selectedSensor.length)
                {
                    bCanSelected = false;
                }
                else
                {
                    for (let x = 0; x < typs.length; x++)
                    {
                        if (this.designs[i].types.indexOf(typs[x]) === -1)
                            bCanSelected = false;
                    }
                }

                if(bCanSelected)
                    designs.push(this.designs[i]);
            }

            return designs;
        },

        GetTypes()
        {
            let types = [];
            for(let i = 0; i < this.types.length; i++)
            {
                if(this.GetSensorsByTyp(this.types[i]).length > 0)
                {
                    types.push(this.types[i]);
                }
            }
            return types;
        },

        GetSensorsByTypes()
        {
            let sensors = [];
            for(let i = 0; i < this.selectedTypes.length; i++)
            {
                let data = this.GetSensorsByTyp(this.selectedTypes[i]);
                for(let p = 0; p < data.length; p++)
                {
                    sensors.push(data[p]);
                }
            }

            return sensors;
        },

        GetSensorsByTyp(typ)
        {
            let sensors = [];
            for(let i = 0; i < this.stations.length; i++)
            {
                for(let j = 0; j < this.stations[i].sensors.length; j++)
                {
                    if(this.stations[i].sensors[j].typ === typ.typ)
                    {
                        sensors.push({station: this.stations[i], sensor: this.stations[i].sensors[j]});
                    }
                }
            }
            return sensors;
        },

        TriggerType(typ)
        {
            let index = -1;
            if((index = this.selectedTypes.indexOf(typ)) >= 0)
            {
                this.selectedTypes.splice(index, 1);
            }
            else
            {
                this.selectedTypes.push(typ);
            }
        },

        CanSelectedSensor(sensorList)
        {
            return sensorList.filter(element => this.selectedDesign.types.indexOf(element.typ) >= 0);
        },

        GetStationFromSensor(sensor)
        {
            for(let i = 0; i < this.stations.length; i++)
            {
                let indexOf = this.stations[i].sensors.indexOf(sensor);
                if(indexOf >= 0)
                    return this.stations[i];
            }
            return null;
        },

        TriggerSensor(sensor)
        {
            console.log(sensor);
            let index = -1;
            if((index = this.selectedSensor.indexOf(sensor)) >= 0)
            {
                console.log("remove");
                this.selectedSensor.splice(index, 1);
            }
            else
            {
                console.log("add");
                this.selectedSensor.push(sensor);
            }

            console.log(this.selectedSensor);
        },

        //Dashboard Layout's resizestart event function
        onResizeStart: function (args) {
            console.log("Resize Start");
        },
        //Dashboard Layout's resize event function
        onResize: function (args) {
            console.log("Resizing");
        },
        //Dashboard Layout's resizestop event function
        onResizeStop: function (args) {
            console.log("Resize Stop")
        },

        genRand(min, max, decimalPlaces) {
            let rand = Math.random() < 0.5 ? ((1 - Math.random()) * (max - min) + min) : (Math.random() * (max - min) + min);  // could be min or max or anything in between
            let power = Math.pow(10, decimalPlaces);
            return Math.floor(rand * power) / power.toFixed(2);
        },

        updateCompass() {
            this.windDegree = this.genRand(0, 360, 2);
            this.windDegreeValue = Math.floor((this.windDegree / 45) * 10) / 10;
            this.windDegreeString = this.compassString(this.windDegree);
        },

        compassString(value) {
            let options = ["N", "NNE", "NE", "ENE", "E", "ESE", "SE", "SSE", "S", "SSW", "SW", "WSW", "W", "WNW", "NW", "NNW"];
            // let degree = value + 11.25 % 360;
            return value + "°<br>" + options[Math.floor(value / 22.5)];
        }
        ,

        generateData(count, settings) {
            let data = [];
            for (let i = 0; i < count; i++) {
                data.push(this.genRand(settings.min, settings.max, 1));
            }
            console.log(data);
            return data;
        }
        ,


        axisLabelRender: function (args) {
            let value = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW', ''];
            args.text = value[args.value];
        },

        load: function (args) {
            let selectedTheme = location.hash.split("/")[1];
            selectedTheme = selectedTheme ? selectedTheme : "Material";
            args.gauge.theme =
                (selectedTheme.charAt(0).toUpperCase() +
                    selectedTheme.slice(1)).replace(/-dark/i, 'Dark').replace(/contrast/i, 'Contrast');
        },

        heatmap() {
            let options = {
                series: [
                    {
                        name: 'KW32',
                        data: this.generateData(7, {
                            min: 15,
                            max: 90
                        })
                    },
                    {
                        name: 'KW31',
                        data: this.generateData(7, {
                            min: 15,
                            max: 90
                        })
                    },
                    {
                        name: 'KW30',
                        data: this.generateData(7, {
                            min: 15,
                            max: 90
                        })
                    },
                    {
                        name: 'KW29',
                        data: this.generateData(7, {
                            min: 15,
                            max: 90
                        })
                    },
                ],
                chart: {
                    height: 145,
                    type: 'heatmap',
                    zoom: {
                        enabled: false
                    },
                    sparkline: {
                        enabled: true
                    },
                    dataLabels: {
                        enabled: false
                    }
                },
                dataLabels: {
                    enabled: false
                },
                tooltip: {
                    // enabled: false
                    colors: ["#000000"],
                    y: {
                        formatter: function (value) {
                            return value + " %";
                        }
                    }
                },
                colors: ["#ff0000"],
            };

            let chart = new ApexCharts(document.querySelector('#chart_heatmap'), options)
            chart.render();
        }
        ,

        humidity_line_chart() {
            let dateForDay = new Date().getTime() - 86400000 + (3 * 3600000);
            let valueData = [];
            for (let i = 0; i < 24; i++) {
                valueData.push([dateForDay + (i * 3600000), this.genRand(10, 35, 1)]);
            }

            this.tempValue = valueData[valueData.length - 1][1];
            this.tempValues = valueData;

            let options = {
                series: [{
                    name: "",
                    data: valueData
                }],
                colors: ["#FFFFFF"],
                chart: {
                    height: '145',
                    type: 'line',
                    zoom: {
                        enabled: false
                    },
                    sparkline: {
                        enabled: true
                    },
                    dataLabels: {
                        enabled: false
                    }
                },
                tooltip: {
                    // enabled: false
                    colors: ["#000000"],
                    x: {
                        format: 'dd.MM.yyyy HH:mm:ss'
                    },
                    y: {
                        formatter: function (value) {
                            return value + " °C";
                        }
                    }
                },
                xaxis: {
                    type: 'datetime',
                },
                stroke: {
                    width: 2
                }
            };

            let chart = new ApexCharts(document.querySelector('#chart_sparkline'), options)
            chart.render();


            // Bar
            options = {
                series: [{
                    name: "",
                    data: [625, 650, 700, 725, 640, 490, 725, 400, 250, 375, 500, 550, 600, 700, 150, 250, 480, 650, 670, 690, 715]
                }],
                colors: ["#FFFFFF"],
                chart: {
                    height: '145',
                    type: 'bar',
                    zoom: {
                        enabled: false
                    },
                    sparkline: {
                        enabled: true
                    },
                    dataLabels: {
                        enabled: false
                    }
                },
                tooltip: {
                    enabled: true,
                    x: {
                        formatter: function (value) {
                            return value + " Uhr";
                        }
                    },
                    y: {
                        formatter: function (value) {
                            return value + " W/m²";
                        }
                    }
                },
            };

            let chartBar = new ApexCharts(document.querySelector('#chart_sparkbar'), options)
            chartBar.render();


            // Tacho
            options = {
                series: [41],
                chart: {
                    height: '190',
                    type: 'radialBar',
                },
                plotOptions: {
                    radialBar: {
                        startAngle: -135,
                        endAngle: 135,
                        dataLabels: {
                            value: {
                                formatter: function () {
                                    return "";
                                }
                            }
                        }
                    }
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        shade: 'dark',
                        shadeIntensity: 0.15,
                        inverseColors: false,
                        opacityFrom: 1,
                        opacityTo: 1,
                        stops: [0, 500, 1000, 2500, 5000]
                    },
                },
                labels: [''],
            };

            let ratioChart = new ApexCharts(document.querySelector("#light_chart"), options);
            ratioChart.render();


            let dateForHour = new Date().getTime() - 3600000;
            let solarData = [];
            /*
            solarData.push([dateForHour + (0 * 60000), 1]);
            solarData.push([dateForHour + (1 * 60000), 4]);
            solarData.push([dateForHour + (2 * 60000), 8]);
            solarData.push([dateForHour + (3 * 60000), 10]);
            solarData.push([dateForHour + (4 * 60000), 10]);
             */
            for (let i = 5; i < 60; i++) {
                solarData.push([dateForHour + (i * 60000), this.genRand(1, 12, 1)]);
            }

            // Points
            options = {
                series: [{
                    name: "",
                    data: solarData
                }],
                colors: ["#FFFFFF"],
                chart: {
                    height: '145',
                    type: 'scatter',
                    zoom: {
                        enabled: false
                    },
                    sparkline: {
                        enabled: true
                    },
                    dataLabels: {
                        enabled: false
                    }
                },
                tooltip: {
                    // enabled: false
                    colors: ["#000000"],
                    x: {
                        format: 'dd.MM.yyyy HH:mm:ss'
                    },
                    y: {
                        formatter: function (value) {
                            return value + " W/m²";
                        }
                    }
                },
                xaxis: {
                    type: 'datetime',
                },
                markers: {
                    size: 4
                }
            };

            let pointChart = new ApexCharts(document.querySelector('#chart_points'), options)
            pointChart.render();
        }
    }
}


</script>
<style>
@import "/node_modules/@syncfusion/ej2-base/styles/material.css";
@import "/node_modules/@syncfusion/ej2-vue-layouts/styles/material.css";

/* DashboardLayout element styles  */
#defaultLayout .e-panel .e-panel-container {
    vertical-align: middle;
    horiz-align: center;
    font-weight: 600;
    font-size: 20px;
    text-align: center;
    background-color: #191919;
    border-radius: 15px;
}

.panel-content {
    line-height: 80px;
    background-color: #191919;
    border-radius: 15px;

}

#defaultLayout .e-panel {
    transition: none !important;
    background-color: #191919;
    border-width: 0;
    border-radius: 15px;
}
</style>
