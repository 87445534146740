import 'bootstrap/dist/css/bootstrap.min.css'
import "@/css/globals.sass";

import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import { createPinia } from 'pinia'
import { MapsPlugin } from '@syncfusion/ej2-vue-maps';
import {CircularGaugePlugin} from "@syncfusion/ej2-vue-circulargauge";
import dayjs from 'dayjs';
import {registerLicense} from "@syncfusion/ej2-base";

import Toast, {POSITION} from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";
import {useToast} from "vue-toastification";

registerLicense("ORg4AjUWIQA/Gnt2VVhjQlFaclhJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRd0ViUX9WcHFQT2lUVEY=");
const app = createApp(App);

const toast = useToast();
app.config.globalProperties.$toast = toast;

app.config.globalProperties.$filters = {
    formatDate(value: string|number) {
        const date = dayjs(value);
        return date.format('DD.MM.YYYY - HH:mm:ss');
    },
}

app.use(Toast, {
    position: POSITION.TOP_RIGHT
});

app.use(createPinia())
    .use(router)
    .use(MapsPlugin)
    .use(CircularGaugePlugin);

app.mount('#app')
