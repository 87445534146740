import axios, { type AxiosInstance } from "axios";

export class API {
    private static instance: API
    private AuthServer: AxiosInstance
    
    private constructor() {
        this.AuthServer = axios.create({
            baseURL: process.env.NODE_ENV === 'development' ? 'https://auth.dev.pce-service.net' : "https://auth.dev.pce-service.net" ,
            timeout: 10000
        });
    }
    
    private static getInstance(): API {
        if (!API.instance) {
            API.instance = new API();
        }
        return API.instance;
    }
    
    public static get AuthServer() {
        return this.getInstance().AuthServer
    }
}