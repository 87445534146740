import { UserStore } from "@/stores/user";
import axios, { type AxiosInstance } from "axios";
import { Station } from "@/api/models/Station";
import { Sensor } from "@/api/models/Sensor";

export class StationsAPI {
    private static get API() : AxiosInstance {
        const jwt = UserStore().jwt
        return axios.create({
            baseURL: process.env.NODE_ENV === 'development' ? 'https://weatherstation.dev.pce-service.net/v1/stations/' : "https://weatherstation.dev.pce-service.net/v1/stations/",
            timeout: 10000,
            headers: {
                'Authorization': `Bearer ${jwt}`
            }            
        });        
    }

    static async getAllStations() : Promise<Station[]>
    {
        return await (await this.API.get("/")).data.data as Station[]
    }

    static async getStationByUUID(uuid : string) : Promise<Station>
    {
        return await (await this.API.get(`/${uuid}`)).data.data as Station
    }

    static async getSensorForStationByUUID(stationUUID : string, sensorUUID : string) : Promise<Sensor>
    {
        return await (await this.API.get(`/${stationUUID}/sensors/${sensorUUID}`)).data.data as Sensor
    }

    static clearSensorMeasurements(stationUUID : string, sensorUUID : string)
    {
        this.API.delete(`/${stationUUID}/sensors/${sensorUUID}/measurements`)
    }
}