<template>
    <div id="content">
        <div class="container">
            <h3>Dashboard</h3>
            <h4>Wetterstation Klimawald Freienohl</h4>


            <div class="row mt-3 justify-content-md-center">
                <div class="col-sm-12">
                    <div class="card">
                        <ejs-maps :zoomSettings="zoomSettings" :centerPosition="centerPosition">
                            <e-layers>
                                <e-layer :urlTemplate='urlTemplate' :markerSettings='markerSettings'></e-layer>
                            </e-layers>
                        </ejs-maps>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {  Marker, } from '@syncfusion/ej2-vue-maps';
export default {
    name: "TestView",

    data () {
        return {
            urlTemplate: "https://tile.openstreetmap.org/level/tileX/tileY.png",
            markerSettings: [
                {
                    dataSource: [
                        {
                            latitude: 51.38463148505049,
                            longitude: 8.163451727074289,
                            name: "PCE-Deutschland GmbH"
                        }
                    ],
                    visible: true,
                    height: 20,
                    width: 20,
                    animationDuration: 0
                }
            ],

            zoomSettings: {
                enable: true,
                toolBars: ["Zoom", "ZoomIn", "ZoomOut", "Pan", "Reset"],
                zoomFactor: 17,
                mouseWheelZoom : true
            },
            centerPosition: {
                latitude: 51.38463148505049,
                longitude: 8.163451727074289
            },
        }
    },
    provide: {
        maps: [Marker]
    }
}
</script>
<style>
.wrapper {
    max-width: 400px;
    margin: 0 auto;
}
</style>