import { createRouter, createWebHistory, RouteRecordRaw, RouterView } from 'vue-router'
import DashBoardView from '../views/Dashboard.vue'
import TestView from '../views/Test.vue';
import AllStationsView from "@/views/station/AllStationsView.vue"
import StationDetailsView from "@/views/station/StationDetailsView.vue"
import SensorDetailsViewVue from '@/views/station/SensorDetailsView.vue';

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        component: DashBoardView,
        props: {
            icon: "fa-house",
            displayName: "Dashboard"
        },
        children: [
            {
                path: '/home2',
                name: 'home2',
                component: DashBoardView,
                props: {
                    hideInSidebarToplevel: true,
                    hideInSidebar: false,
                    displayName: "Wetterstation Klimawald Freienohl"
                },                
            },
            {
                path: '/home3',
                name: 'home3',
                component: DashBoardView,
                props: {
                    hideInSidebarToplevel: true,
                    hideInSidebar: false,
                    displayName: "Kalibrierlabor"
                },                
            },
            {
                path: '/home4',
                name: 'home4',
                component: DashBoardView,
                props: {
                    hideInSidebarToplevel: true,
                    hideInSidebar: false,
                    displayName: "Meschede-Olpe"
                }               
            }                        
        ]
    },
    {
        path: '/stations',
        component: RouterView,
        props: {
            icon: "fa-sensor",
            displayName: "Stationen"
        },
        children: [
            {
                path: '',
                name: 'stations',
                component: AllStationsView,
                props: {
                    hideInSidebarToplevel: true,
                    hideInSidebar: true
                }
            },            
            {
                path: ':uuid',
                name: 'stationdetails',
                component: StationDetailsView,
                props: {
                    hideInSidebarToplevel: true,
                    hideInSidebar: true
                }
            },
            {
                path: '/:stationuuid/sensors/:uuid',
                name: 'sensordetails',
                component: SensorDetailsViewVue,
                props: {
                    hideInSidebarToplevel: true,
                    hideInSidebar: true
                }
            },            
        ]
    },
    {
        path: '/map',
        name: 'map',
        component: TestView,
        props: {
            icon: "fa-map-location-dot",
            displayName: "Karte"
        }
    },
    {
        path: '/settings',
        name: 'settings',
        component: TestView,
        props: {
            icon: "fa-gears",
            displayName: "Einstellungen"
        }
    },
    {
        path: '/logout',
        name: 'logout',
        component: TestView,
        props: {
            icon: "fa-right-from-bracket",
            displayName: "Logout"
        }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router