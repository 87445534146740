import { defineStore } from 'pinia'
import * as jose from 'jose'
import { API } from "@/api"

async function parsePublicKey(spki : string) : Promise<jose.KeyLike> {
  const algorithm = 'ES512'
  return await jose.importSPKI(spki, algorithm)
}

export const UserStore = defineStore('user', {
  state: () => ({
    jwt: "",
    refreshtoken: "",
    uuid: "",
    sub: "",
    publickey: "",
    parsedPublicKey: null as jose.KeyLike | null,
    multiFactorRequired: false
  }),

  getters: {
    isTokenValid(state) {
      return state.jwt.length > 0 && !state.multiFactorRequired
    },
  },

  actions: {
    async login(username : string, password : string) {
      try {        
        const response = await API.AuthServer.post("/v1/auth", {
          username: username,
          password: password
        })
        
        if(response.status === 201)
        {
          this.multiFactorRequired = true
          this.jwt = response.data.data.jwt
        }
        else
        {
          localStorage.jwt = response.data.data.jwt
          localStorage.refreshtoken = response.data.data.refreshtoken
          localStorage.publickey = response.data.data.publickey
        }

        return this.loginWithTokenFromStorage()
      } catch(e) {
        return false;
      }
    },

    async login2FA(code : string) {
      try {        
        const response = await API.AuthServer.post("/v1/auth/2fa", {
          code: code
        }, {
          headers: {
            'Authorization': `Bearer ${this.jwt}`
          }
        });
        
        this.multiFactorRequired = false;
        localStorage.jwt = response.data.data.jwt
        localStorage.refreshtoken = response.data.data.refreshtoken
        localStorage.publickey = response.data.data.publickey

        return this.loginWithTokenFromStorage()
      } catch(e) {
        return false;
      }
    },    

    async loginWithTokenFromStorage() {
      const [jwt, refreshToken, publicKey] = [localStorage.jwt, localStorage.refreshtoken, localStorage.publickey]
      this.publickey = publicKey
      this.refreshtoken = refreshToken      

      if(jwt && publicKey)
      {
        this.parsedPublicKey = await parsePublicKey(publicKey)      
        try {
          const claims = (await jose.jwtVerify(jwt, this.parsedPublicKey)).payload
          
          this.jwt = jwt
          this.sub = claims.sub as string
          this.uuid = claims.subuuid as string

          return true
        }
        catch(e : any) {
          if(e.code === "ERR_JWT_EXPIRED")
            return this.reauthUsingRefreshToken()
        }
      }
      return false
    },

    async reauthUsingRefreshToken() {
      console.log("Reauth TODO")
      return false
    },

    logout() {
      this.jwt = ""
      this.refreshtoken = ""
      this.uuid = ""
      this.sub = ""
      this.publickey = ""
      this.parsedPublicKey = null

      delete localStorage.jwt
      delete localStorage.refreshtoken
      delete localStorage.publickey
    }
  },
})